import React from "react"

import PotDisplay from "./PotDisplay"
import PotButtons from "./PotButtons"

const PotContainer = ({
  pots,
  extState,
  localState,
  setLocalState,
  dailyDataStatus,
  disabled,
  handleBuyData,
  handleBuyDataReload,
  handleBuySuperboost,
  handleBuyEUData,
  handleDailyData,
  handleChangeDataLevel,
  handleSubscriptionPause,
  onClick
}) => {
  const showLegend = pots && pots.length > 0 && pots[0].ShowDetails
  const handleButtons = () => {
    if (extState.CanBuyData) {
      if (showLegend) {
        setTimeout(function () {
          setLocalState({ showBuyDataButton: true })
        }, 150)
      } else {
        setTimeout(function () {
          setLocalState({ showBuyDataButton: false })
        }, 50)
      }
    }
    if (extState.CanBuyEUData) {
      if (showLegend) {
        setTimeout(function () {
          setLocalState({ showBuyEUDataButton: true })
        }, 150)
      } else {
        setTimeout(function () {
          setLocalState({ showBuyEUDataButton: false })
        }, 50)
      }
    }
    if (extState.CanBuySuperboost) {
      if (showLegend) {
        setTimeout(function () {
          setLocalState({ showBuySuperboostButton: true })
        }, 150)
      } else {
        setTimeout(function () {
          setLocalState({ showBuySuperboostButton: false })
        }, 50)
      }
    }
    if (extState.CanBuyDataReload) {
      if (showLegend) {
        setTimeout(function () {
          setLocalState({ showBuyDataReloadButton: true })
        }, 150)
      } else {
        setTimeout(function () {
          setLocalState({ showBuyDataReloadButton: false })
        }, 50)
      }
    }
    if (extState.IsNorway && extState.CanChangePriceplan && extState.IsData) {
      if (showLegend) {
        setTimeout(function () {
          setLocalState({ showChangeDataLevelButton: true })
        }, 150)
      } else {
        setTimeout(function () {
          setLocalState({ showChangeDataLevelButton: false })
        }, 50)
      }
    }
    if (extState.IsDailyData) {
      if (showLegend) {
        setTimeout(function () {
          setLocalState({ showStartPauseButton: true })
        }, 150)
      } else {
        setTimeout(function () {
          setLocalState({ showStartPauseButton: false })
        }, 50)
      }
    }
    if (extState.CanPause) {
      setTimeout(function () {
        setLocalState({ showPauseButton: true })
      }, 150)
    }
    if (extState.CanUnPause) {
      setTimeout(function () {
        setLocalState({ showUnPauseButton: true })
      }, 150)
    }
  }

  return (
    <>
      <PotDisplay
        pots={pots}
        dailyDataStatus={dailyDataStatus}
        onClick={() => {
          handleButtons()
          if (!!onClick) onClick()
        }}
        showLegend={pots && pots.length > 0 && pots[0].ShowDetails}
        legendShadow={true}
      />
      <PotButtons
        pots={pots}
        state={extState}
        localState={localState}
        setLocalState={setLocalState}
        disabled={disabled}
        handleBuyData={handleBuyData}
        handleBuyDataReload={handleBuyDataReload}
        handleBuySuperboost={handleBuySuperboost}
        handleBuyEUData={handleBuyEUData}
        handleDailyData={handleDailyData}
        handleChangeDataLevel={handleChangeDataLevel}
        handleSubscriptionPause={handleSubscriptionPause}
      />
    </>
  )
}

export default PotContainer
