import React, { useState, useEffect } from "react";
import auth from "../auth";
import Version from "../version";
import ServerState from '../controls/ServerState';
import ReactLoading from 'react-loading';
import { useConnect } from 'dotnetify';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Grid, Divider, FormControl, FormControlLabel, FormHelperText, Checkbox, Link } from '@mui/material';
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import Icon from "../components/Selfcare/Common/Icon";
import { TextField } from '../controls/TextField';
import TextMaskNumeric from '../components/Selfcare/TextMasks/TextMaskNumeric';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';

export default function DirectDebit(props) {
    const { t } = useTranslation();
    const { vm, state, setState } = useConnect("DirectDebit", { buzy: false, completed: false, cancelled: false, anchorEl: null, DataLoading: true, clearingNo: '', accountNo: '', consentPayment: false, consentDirectDebit: false, HasDirectDebit: null },
        {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            vmArg: {
                SubscriptionNo: props.SubscriptionNo,
            },
            exceptionHandler: _ => auth.signOut()
        });
    const theme = useTheme();
    const handlePopupClick = (event, popoverId) => {
        setState({
            openedPopoverId: popoverId,
            anchorEl: event.currentTarget,
        });
    };

    const handlePopupClose = () => {
        setState({
            openedPopoverId: null,
            anchorEl: null,
        });
    };

    return <>
        {state.HasDirectDebit !== null ?
            <>
                <ServerState serverState={state} setState={setState} />
                {state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: state.AdministratedMsisdn }) }} /></div>}
                <>
                    {!state.HasDirectDebit || (state.HasDirectDebit && state.completed) ?
                        <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '17px' }}>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} sm={10}>
                                    <Icon name="content-level" size={48} color="#FFFFFF" />
                                    <div style={{ fontSize: '18px', fontWeight: '700', maxWidth: '900px', marginBottom: '10px' }}>
                                        Betala via Autogiro. Ett smidigt sätt att inte missa en betalning.
                                    </div>
                                    <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '16px' }}>
                                        Hos oss kan du betala via Autogiro och då dras pengar från ditt angivna bankkonto på förfallodagen.
                                        Du kan antingen ansöka om Autogirobetalning via din internetbank eller så kan du göra det genom att ange dina uppgifter nedan. Chilimobil sköter då all kontakt med Autogirot och din bank
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        :
                        <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '17px' }}>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} sm={10}>
                                    <Icon name="content-level" size={48} color="#FFFFFF" />
                                    <div style={{ fontSize: '18px', fontWeight: '700', maxWidth: '900px', marginBottom: '10px' }}>
                                        Du har autogiro kopplat till oss idag.
                                    </div>
                                    <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '16px' }}>
                                        Här kan du ändra kontonummer som skall vara kopplat till ditt autogiro eller avsluta kopplingen mot autogirot helt.<br />
                                        Om du väljer att avsluta betalning via autogirå så kommer du fortsättningsvis att få din faktura på via e-post och betala med bankgiro eller med betalkort inne på dina sidor i MittChili.
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    }
                    {!state.HasDirectDebit &&
                        <>
                            <div>
                                {!state.completed &&
                                    <Grid container justifyContent="center" style={{ padding: '17px' }}>
                                        <Grid item xs={12} sm={10}>
                                            <p>
                                                <b>Betalning via Autogiro</b>
                                            </p>
                                            <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '16px' }}>
                                                <p>
                                                    Om du vill att dina fakturor från Chilimobil betalas via Autogiro, var vänlig fyll i uppgifter kring ditt bankkonto nedan. Chilimobil ordnar med koppling till din bank och Autogirot.
                                                    Dina uppgifter hanteras i enlighet med Personuppgiftslagen.
                                                </p>

                                                <p>
                                                    Ditt fakturaunderlag kommer som vanligt att finnas tillgängligt via ”MittChili”, men betalningen sker automatiskt på förfallodagen från det konto du anger nedan.
                                                </p>
                                            </div>
                                            <Grid container direction="row" style={{ marginTop: '17px' }} alignItems="center">
                                                <Grid item xs={6} sm={3} style={{ marginRight: '20px', paddingTop: '10px' }}>
                                                    <TextField
                                                        fullWidth
                                                        noPadding
                                                        name="clearingNo"
                                                        localState={state}
                                                        setLocalState={setState}
                                                        label="Clearingnummer"
                                                        maxLength={5}
                                                        inputComponent={TextMaskNumeric}
                                                        disabled={state.buzy}
                                                    />
                                                </Grid>
                                                <Grid item xs={11} sm={7} style={{ paddingTop: '10px' }}>
                                                    <TextField
                                                        fullWidth
                                                        noPadding
                                                        name="accountNo"
                                                        localState={state}
                                                        setLocalState={setState}
                                                        label="Kontonummer"
                                                        maxLength={10}
                                                        inputComponent={TextMaskNumeric}
                                                        disabled={state.buzy}
                                                    />


                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div>
                                                        <IconButton
                                                            aria-owns="accountNo"
                                                            aria-haspopup="true"
                                                            aria-label={t('selfcare:label.help')}
                                                            onClick={(e) => { handlePopupClick(e, "accountNoHelp"); }}
                                                            size="large">
                                                            <Icon name="information" size={24} color={theme.palette.primary.main} />
                                                        </IconButton>
                                                        <Popover
                                                            id="accountNoHelp"
                                                            open={state.openedPopoverId === "accountNoHelp"}
                                                            anchorEl={state.anchorEl}
                                                            onClose={handlePopupClose}
                                                            anchorOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'left',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'center',
                                                                horizontal: 'right',
                                                            }}
                                                        >
                                                            <>
                                                                <div style={{ textAlign: 'right' }}>
                                                                    <IconButton role="button" aria-label={state.IsSweden ? 'Stäng' : 'Lukk'} size="small" onClick={(e) => { handlePopupClose(); }}>
                                                                        <Icon name="close" size={24} color={theme.palette.primary.main} />
                                                                    </IconButton>
                                                                </div>
                                                                <div style={{ padding: '10px', maxWidth: '500px', fontSize: '12px', fontFamily: theme.typography.fontFamily2, marginTop: '-40px' }}>
                                                                    <h3>
                                                                        Format på olika kontonummer
                                                                    </h3>
                                                                    <ul>
                                                                        <li>
                                                                            Clearingnumret är det fyrsiffriga nummer som betecknar ett kontor eller en avdelning hos din bank.
                                                                        </li>
                                                                        <li>
                                                                            Normalt är clearingnumret de första fyra siffrorna i kontonumret, tex<br />
                                                                            6001 XXX XXX XXX (Handelsbanken)<br />
                                                                            5201 XXXXX XX (SEB)
                                                                        </li>
                                                                        <li>
                                                                            Swedbank har ibland fem siffror, ex 83279-XXXXX. Utelämna då femte siffran, i detta fall 9.
                                                                        </li>
                                                                        <li>
                                                                            Handelsbankens clearingnummer ska alltid börja med 6. Hittar du inte det ange 6000.

                                                                        </li>
                                                                        <li>
                                                                            Personkonto i Nordea där kontonumret är samma som ditt personnummer, clearingnummer = 3300 – ÅÅMMDDXXXX.
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </>
                                                        </Popover>
                                                    </div>

                                                </Grid>
                                            </Grid>
                                            <Grid container direction="column" justifyContent="flex-start" style={{ marginTop: '17px' }}>
                                                <FormControl error={state.ErrorField === 'consentPayment'}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                id="consentPayment"
                                                                checked={state.consentPayment}
                                                                onChange={() => { setState({ consentPayment: !state.consentPayment, ErrorField: '' }) }}
                                                                name="consentPayment"
                                                                color="primary"
                                                                disabled={state.buzy}
                                                            />
                                                        }
                                                        label={
                                                            <div style={{ fontSize: '16px', fontFamily: theme.typography.fontFamily2 }}>
                                                                Härmed godkänner jag att fakturabeloppet dras från mitt konto via Autogiro.
                                                            </div>
                                                        }
                                                    />
                                                    {state.ErrorField === 'consentPayment' && <div style={{ paddingLeft: '30px' }}><FormHelperText>{state.ErrorText}</FormHelperText></div>}
                                                </FormControl>
                                                <FormControl error={state.ErrorField === 'consentDirectDebit'}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                id="consentDirectDebit"
                                                                checked={state.consentDirectDebit}
                                                                onChange={() => { setState({ consentDirectDebit: !state.consentDirectDebit, ErrorField: '' }) }}
                                                                name="consentDirectDebit"
                                                                color="primary"
                                                                disabled={state.buzy}
                                                            />
                                                        }
                                                        label={
                                                            <div style={{ fontSize: '16px', fontFamily: theme.typography.fontFamily2 }}>
                                                                Härmed godkänner jag de <Link color="primary" onClick={() => { props.openUrl(state.DirectDebitTermsUrl); }}>villkor</Link> som gäller avseende medgivande om Autogiro.
                                                            </div>
                                                        }
                                                    />
                                                    {state.ErrorField === 'consentDirectDebit' && <div style={{ paddingLeft: '30px' }}><FormHelperText>{state.ErrorText}</FormHelperText></div>}
                                                </FormControl>
                                                <Grid container direction="row" justifyContent="center" style={{ marginTop: '17px' }}>
                                                    <LargeButton onClick={() => { setState({ buzy: true }); vm.$dispatch({ RegisterDirectDebit: { AcceptPaymentTerms: state.consentPayment, AcceptDirectDebitTerms: state.consentDirectDebit, ClearingNo: state.clearingNo, AccountNo: state.accountNo } }); }} disabled={state.buzy}>Skicka</LargeButton>
                                                    <LargeButton color="secondary" variant="text" id='cancel' disabled={state.buzy} onClick={() => { props.handleClose(); }}>{t('selfcare:button.cancel')}</LargeButton>
                                                </Grid>
                                                <Grid container direction="row" justifyContent="center">
                                                    {state.buzy && <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {state.completed &&
                                    <>
                                        <Grid container justifyContent="center" style={{ padding: '17px' }}>
                                            <Grid item xs={12} sm={10}>
                                                <p>
                                                    <b>Tack !</b>
                                                </p>
                                                <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '16px' }}>
                                                    <p>
                                                        Vi har tagit emot dina uppgifter.
                                                    </p>
                                                    <p>
                                                        En ansökan om Autogiro tar några dagar att verifiera med banken.<br />
                                                        Om du nyligen fått en faktura, så rekommenderar vi dig att betala den via bankgiro hos din internetbank eller med betalkort här på MittChili. Detta för att inte riskera att förfallodatum kan passera innan Autogiro är godkänt.
                                                    </p>
                                                    <p>
                                                        Vi hör av oss om det skulle uppstå något problem med ansökan.
                                                    </p>
                                                    <p>
                                                        Har du frågor till oss, så är du mer än välkommen att kontakta oss via mejl, <Link href="mailto:kundservice@chilimobil.se">kundservice@chilimobil.se</Link>.
                                                    </p>
                                                    <p>
                                                        //
                                                    </p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" justifyContent="center" style={{ marginTop: '17px' }}>
                                            <LargeButton onClick={() => { props.handleClose(); }} disabled={state.buzy}>Stäng</LargeButton>
                                        </Grid>
                                    </>
                                }
                            </div>
                        </>}
                    {state.HasDirectDebit &&
                        <>
                            <div>
                                {!state.completed &&
                                    <>
                                        <Grid container justifyContent="center" style={{ padding: '17px' }}>
                                            <Grid item xs={12} sm={10}>
                                                <p>
                                                    <b>Du har autogiro kopplat till ditt konto för betalningar av fakturor från Chilimobil.</b>
                                                </p>
                                                <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '16px' }}>
                                                    <p>
                                                        Följande konto är kopplat till autogiro: <b>{state.CurrentAccount}</b>
                                                    </p>
                                                </div>
                                                <Divider style={{ width: '100%', marginBottom: '17px', marginTop: '17px' }} />
                                                <p>
                                                    <p>
                                                        <b>Jag vill byta konto som skall vara kopplat till autogiro.</b>
                                                    </p>
                                                </p>
                                                <Grid container direction="row" style={{ marginTop: '17px' }} alignItems="center">
                                                    <Grid item xs={6} sm={3} style={{ marginRight: '20px', paddingTop: '10px' }}>
                                                        <TextField
                                                            fullWidth
                                                            noPadding
                                                            name="clearingNo"
                                                            localState={state}
                                                            setLocalState={setState}
                                                            label="Clearingnummer"
                                                            maxLength={5}
                                                            inputComponent={TextMaskNumeric}
                                                            disabled={state.buzy}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={11} sm={7} style={{ paddingTop: '10px' }}>
                                                        <TextField
                                                            fullWidth
                                                            noPadding
                                                            name="accountNo"
                                                            localState={state}
                                                            setLocalState={setState}
                                                            label="Kontonummer"
                                                            maxLength={10}
                                                            inputComponent={TextMaskNumeric}
                                                            disabled={state.buzy}
                                                        />


                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <div>
                                                            <IconButton
                                                                aria-owns="accountNo"
                                                                aria-haspopup="true"
                                                                aria-label={t('selfcare:label.help')}
                                                                onClick={(e) => { handlePopupClick(e, "accountNoHelp"); }}
                                                                size="large">
                                                                <Icon name="information" size={24} color={theme.palette.primary.main} />
                                                            </IconButton>
                                                            <Popover
                                                                id="accountNoHelp"
                                                                open={state.openedPopoverId === "accountNoHelp"}
                                                                anchorEl={state.anchorEl}
                                                                onClose={handlePopupClose}
                                                                anchorOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'left',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'center',
                                                                    horizontal: 'right',
                                                                }}
                                                            >
                                                                <>
                                                                    <div style={{ textAlign: 'right' }}>
                                                                        <IconButton role="button" aria-label={state.IsSweden ? 'Stäng' : 'Lukk'} size="small" onClick={(e) => { handlePopupClose(); }}>
                                                                            <Icon name="close" size={24} color={theme.palette.primary.main} />
                                                                        </IconButton>
                                                                    </div>
                                                                    <div style={{ padding: '10px', maxWidth: '500px', fontSize: '12px', fontFamily: theme.typography.fontFamily2, marginTop: '-40px' }}>
                                                                        <h3>
                                                                            Format på olika kontonummer
                                                                        </h3>
                                                                        <ul>
                                                                            <li>
                                                                                Clearingnumret är det fyrsiffriga nummer som betecknar ett kontor eller en avdelning hos din bank.
                                                                            </li>
                                                                            <li>
                                                                                Normalt är clearingnumret de första fyra siffrorna i kontonumret, tex<br />
                                                                                6001 XXX XXX XXX (Handelsbanken)<br />
                                                                                5201 XXXXX XX (SEB)
                                                                            </li>
                                                                            <li>
                                                                                Swedbank har ibland fem siffror, ex 83279-XXXXX. Utelämna då femte siffran, i detta fall 9.
                                                                            </li>
                                                                            <li>
                                                                                Handelsbankens clearingnummer ska alltid börja med 6. Hittar du inte det ange 6000.

                                                                            </li>
                                                                            <li>
                                                                                Personkonto i Nordea där kontonumret är samma som ditt personnummer, clearingnummer = 3300 – ÅÅMMDDXXXX.
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </>
                                                            </Popover>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <Grid container direction="row" justifyContent="center" style={{ marginTop: '17px' }}>
                                                    <LargeButton onClick={() => { setState({ buzy: true }); vm.$dispatch({ RegisterDirectDebit: { AcceptPaymentTerms: true, AcceptDirectDebitTerms: true, ClearingNo: state.clearingNo, AccountNo: state.accountNo } }); }} disabled={state.buzy}>Skicka</LargeButton>
                                                </Grid>
                                                <Divider style={{ width: '100%', marginBottom: '17px', marginTop: '17px' }} />
                                                <p>
                                                    <b>Jag vill avbryta kopplingen till autogiro.</b>
                                                </p>
                                                <Grid container direction="row" justifyContent="center" style={{ marginTop: '17px' }}>
                                                    <LargeButton onClick={() => { setState({ buzy: true }); vm.$dispatch({ CancelDirectDebit: true }); }} disabled={state.buzy}>Avsluta</LargeButton>
                                                </Grid>
                                                <Divider style={{ width: '100%', marginBottom: '17px', marginTop: '17px' }} />
                                                <Grid container direction="row" justifyContent="center">
                                                    <LargeButton color="secondary" variant="text" id='cancel' disabled={state.buzy} onClick={() => { props.handleClose(); }}>{t('selfcare:button.cancel')}</LargeButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                                {state.completed && !state.cancelled &&
                                    <>
                                        <Grid container justifyContent="center" style={{ padding: '17px' }}>
                                            <Grid item xs={12} sm={10}>
                                                <p>
                                                    <b>Tack !</b>
                                                </p>
                                                <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '16px' }}>
                                                    <p>
                                                        Vi har tagit emot dina uppgifter om förändring av kontonummer kopplat till ditt autogiro hos Chilimobil.
                                                    </p>
                                                    <p>
                                                        En ansökan om Autogiro eller ändring av kontonummer tar några dagar att verifiera med banken.
                                                        Om du nyligen fått en faktura, så rekommenderar vi dig att betala den via bankgiro hos din internetbank eller med betalkort här på MittChili. Detta för att inte riskera att förfallodatum kan passera innan Autogiro är godkänt.
                                                    </p>
                                                    <p>
                                                        Vi hör av oss om det skulle uppstå något problem.
                                                    </p>
                                                    <p>
                                                        Har du frågor till oss, så är du mer än välkommen att kontakta oss via mejl, <Link href="mailto:kundservice@chilimobil.se">kundservice@chilimobil.se</Link>.
                                                    </p>
                                                    <p>
                                                        //
                                                    </p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" justifyContent="center" style={{ marginTop: '17px' }}>
                                            <LargeButton onClick={() => { props.handleClose(); }} disabled={state.buzy}>Stäng</LargeButton>
                                        </Grid>
                                    </>
                                }
                                {state.completed && state.cancelled &&
                                    <>
                                        <Grid container justifyContent="center" style={{ padding: '17px' }}>
                                            <Grid item xs={12} sm={10}>
                                                <p>
                                                    <b>Tack !</b>
                                                </p>
                                                <div style={{ fontFamily: theme.typography.fontFamily2, fontSize: '16px' }}>
                                                    <p>
                                                        Vi har tagit emot dina uppgifter om att du vill avsluta dragning av fakturabelopp via autogiro.
                                                    </p>
                                                    <p>
                                                        Om du nyligen fått en faktura, så rekommenderar vi dig att betala den via bankgiro hos din internetbank eller med betalkort här på MittChili. Detta för att inte riskera att faktura kan förbli obetald. Du kan lätt se dina fakturor via dina sidor i MittChili.
                                                    </p>
                                                    <p>
                                                        Vi hör av oss om det skulle uppstå något problem.
                                                    </p>
                                                    <p>
                                                        Har du frågor till oss, så är du mer än välkommen att kontakta oss via mejl, <Link href="mailto:kundservice@chilimobil.se">kundservice@chilimobil.se</Link>.
                                                    </p>
                                                    <p>
                                                        //
                                                    </p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" justifyContent="center" style={{ marginTop: '17px' }}>
                                            <LargeButton onClick={() => { props.handleClose(); }} disabled={state.buzy}>Stäng</LargeButton>
                                        </Grid>
                                    </>
                                }
                            </div>
                        </>}
                </>
            </>
            :
            <div id="preloader">
                <div id="loader"></div>
            </div>

        }
    </>;
}