import React, { useEffect, useState } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import globalState from '../state/GlobalState';
import { useState as useGlobalState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import { useTheme } from '@mui/material/styles';
import * as rdd from 'react-device-detect';
import isWebview from 'is-ua-webview';
import LeftChevronIcon from '../assets/img/svg/left-chevron.svg';
import ChiliNoLogo from '../assets/img/svg/ChiliNoLogoSmall.svg';
import { Grid } from "@mui/material";
import { Link } from 'react-tiger-transition';

const { forwardRef, useImperativeHandle } = React;

const PageContainer = forwardRef((props, ref) => {
    const wDim = useWindowDimensions();
    const gstate = useGlobalState(globalState);
    const [leftMargin, setLeftMargin] = useState('0px');
    const [id, setId] = useState(props.id);
    const { t } = useTranslation();
    const theme = useTheme();
    const swipeHandler = useSwipeable({
        onSwipeStart: (eventData) => {
            if (eventData.dir === 'Right') {
                var el = window.document.getElementById('back');
                if (el) {
                    el.click();
                }
            }
        }
        //...config,
    });
    const setVoiceOverFocus = (element) => {
        var focusInterval = 10; // ms, time between function calls
        var focusTotalRepetitions = 10; // number of repetitions

        element.setAttribute('tabindex', '0');
        element.blur();

        var focusRepetitions = 0;
        var interval = window.setInterval(function () {
            element.focus();
            focusRepetitions++;
            if (focusRepetitions >= focusTotalRepetitions) {
                window.clearInterval(interval);
            }
        }, focusInterval);
    }
    useImperativeHandle(ref, () => ({
        reDraw() {
            var el = document.getElementById('pageContainer-' + id);
            if (el) {
                setLeftMargin("-" + el.offsetLeft + "px")
            } else {
                setLeftMargin("0px");
            }
        }
    }));

    useEffect(() => {
        function handleResize() {
            var el = document.getElementById('pageContainer-'+id);
            if (el) {
                setLeftMargin("-" + el.offsetLeft + "px")
            } else {
                setLeftMargin("0px");
            }
        }
        setTimeout(
            function () {
                handleResize();
                var el = document.getElementById('back');
                if (el)
                    setVoiceOverFocus(el);
            },
            150
        );

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', overflowX: 'hidden', overflowY: rdd.isFirefox ? 'auto' : wDim.desktop ? 'overlay' : 'scroll', height: wDim.height, WebkitOverflowScrolling: 'touch', scrollbarWidth: rdd.isFirefox ? 'thin' : null }}>
            <div {...swipeHandler} style={{ maxWidth: wDim.desktop ? '500px' : 'unset', width: '100%' }} className={props.noSelect ? 'noselect' : ''}>
                <Grid container direction="row" justifyContent="space-around" alignItems="center" style={{ paddingBottom: wDim.phone || wDim.tablet ? '100px' : '0px' }}>
                    <div id={'pageContainer-' + id} style={{ width: '100%', padding: wDim.phone ? '5px 7px' : '25px 17px' }}>
                        {rdd.isIOS && isWebview && <div style={{ width: '100%', height: '10px' }} />}
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            {props.back && <div style={{ marginTop: '10px' }}><Link role="button" tabIndex="0" aria-label={(gstate.IsSweden.get() ? 'Tillbaka till ' : 'Tilbake til ') + gstate.topModuleName.get()} id="back" replace to={gstate.topModuleUrl.get() ? gstate.topModuleUrl.get() : '/home'} onClick={() => { window.history.back(); }} transition="glide-right" style={{ outline: 'none', marginLeft: leftMargin, textDecoration: 'none', fontSize: '14px', fontFamily: theme.typography.fontFamily2, fontWeight: 300 }}><img src={LeftChevronIcon} height="32" alt="" style={{ marginTop: '-5px', marginRight: '-6px' }} /><div style={{ verticalAlign: 'top', marginTop: '2px', color: theme.palette.primary.main, display: 'inline-block' }}>{gstate.topModuleName.get()}</div></Link></div>}
                            {props.logo && <div style={{ marginLeft: wDim.desktop ? '54px' : '0px', width: '54px' }}><img src={ChiliNoLogo} height="32" alt="" /></div>}
                            {props.title && <div style={{ marginLeft: wDim.desktop ? '54px' : '0px', fontSize: '20px', fontWeight: '700' }}>{props.title}</div>}
                            <div style={{ width: '50px' }}></div>
                        </Grid>
                        <Grid container direction="column" justifyContent="space-around" alignItems="center">
                            {props.children}
                        </Grid>
                    </div>
                </Grid>
            </div>
        </div>
    );
});

export default PageContainer;