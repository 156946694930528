import React, { useEffect } from "react"
import { Grid, Fade } from "@mui/material"
import { SmallButton, LargeButton } from "./components/Buttons"

const PotButtons = ({
  pots,
  state,
  localState,
  setLocalState,
  disabled,
  handleBuyData,
  handleBuyDataReload,
  handleBuySuperboost,
  handleBuyEUData,
  handleDailyData,
  handleChangeDataLevel,
  handleSubscriptionPause
}) => {
  const showLegend = pots && pots.length > 0 && pots[0].ShowDetails
  let disableSuperboost = false
  let disableDataReload = false
  if (pots && pots.length > 0) disableSuperboost = !pots[0].CanBuySuperboost
  else disableSuperboost = true
  if (pots && pots.length > 0) disableDataReload = !pots[0].CanBuyDataReload
  else disableDataReload = true

  useEffect(() => {
    setLocalState({ showBuyDataButton: state.CanBuyData && !showLegend })
    setLocalState({
      showChangeDataLevelButton:
        state.CanChangePriceplan &&
        state.IsData &&
        state.IsNorway &&
        !showLegend
    })
  }, [state.CanBuyData])
  useEffect(() => {
    setLocalState({ showBuyEUDataButton: state.CanBuyEUData && !showLegend })
  }, [state.CanBuyEUData])
  useEffect(() => {
    setLocalState({
      showBuySuperboostButton: state.CanBuySuperboost && !showLegend
    })
  }, [state.CanBuySuperboost])
  useEffect(() => {
    setLocalState({
      showBuyDataReloadButton: state.CanBuyDataReload && !showLegend
    })
  }, [state.CanBuyDataReload])
  useEffect(() => {
    setLocalState({ showStartPauseButton: state.IsDailyData && !showLegend })
  }, [state.IsDailyData])
  useEffect(() => {
    setLocalState({ showPauseButton: state.CanPause })
  }, [state.CanPause])
  useEffect(() => {
    setLocalState({ showUnPauseButton: state.CanUnPause })
  }, [state.CanUnPause])

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Fade
          in={localState.showBuyDataReloadButton}
          style={{
            display: localState.showBuyDataReloadButton ? "block" : "none"
          }}
        >
          <div>
            <LargeButton
              disabled={disabled || disableDataReload}
              style={{ margin: "17px 8px 13px" }}
              onClick={() => {
                handleBuyDataReload()
              }}
            >
              {state.IsSweden ? "Köp Data Reload" : "Bestill Data Reload"}
            </LargeButton>
          </div>
        </Fade>
        <Fade
          in={localState.showBuySuperboostButton}
          style={{
            display: localState.showBuySuperboostButton ? "block" : "none"
          }}
        >
          <div>
            <LargeButton
              disabled={disabled || disableSuperboost}
              style={{ margin: "17px 8px 13px" }}
              onClick={() => {
                handleBuySuperboost()
              }}
            >
              {state.IsSweden ? "Köp Full Fart" : "+ Bestill Superboost"}
            </LargeButton>
          </div>
        </Fade>
        <Fade
          in={localState.showBuyDataButton}
          style={{ display: localState.showBuyDataButton ? "block" : "none" }}
        >
          <div>
            <LargeButton
              disabled={disabled}
              style={{ margin: "17px 8px 13px" }}
              onClick={() => {
                handleBuyData()
              }}
            >
              {state.IsSweden ? "Köp mer Surf" : "+ Bestill datapakke"}
            </LargeButton>
          </div>
        </Fade>
        <Fade
          in={localState.showBuyEUDataButton}
          style={{ display: localState.showBuyEUDataButton ? "block" : "none" }}
        >
          <div>
            <LargeButton
              disabled={disabled}
              style={{ margin: "17px 8px 13px" }}
              onClick={() => {
                handleBuyEUData()
              }}
            >
              {state.IsSweden ? "Köp mer EU-Surf" : "+ Bestill EU-datapakke"}
            </LargeButton>
          </div>
        </Fade>
        <Fade
          in={localState.showChangeDataLevelButton}
          style={{
            display: localState.showChangeDataLevelButton ? "block" : "none"
          }}
        >
          <div>
            <LargeButton
              disabled={disabled}
              style={{ margin: "17px 8px 13px" }}
              onClick={() => {
                handleChangeDataLevel()
              }}
            >
              Endre datagrense
            </LargeButton>
          </div>
        </Fade>
        <Fade
          in={localState.showStartPauseButton}
          style={{
            display: localState.showStartPauseButton ? "block" : "none"
          }}
        >
          <div>
            <SmallButton
              disabled={disabled}
              style={{ margin: "5px 8px 17px" }}
              onClick={() => {
                handleDailyData()
              }}
            >
              {state.StartPauseButtonText}
            </SmallButton>
          </div>
        </Fade>
        <Fade
          in={localState.showPauseButton}
          style={{ display: localState.showPauseButton ? "block" : "none" }}
        >
          <div>
            <LargeButton
              disabled={disabled}
              style={{ margin: "17px 8px 13px" }}
              onClick={() => {
                handleSubscriptionPause(true)
              }}
            >
              Sett på pause
            </LargeButton>
          </div>
        </Fade>
        <Fade
          in={localState.showUnPauseButton}
          style={{ display: localState.showUnPauseButton ? "block" : "none" }}
        >
          <div>
            <LargeButton
              disabled={disabled}
              style={{ margin: "17px 8px 13px" }}
              onClick={() => {
                handleSubscriptionPause(false)
              }}
            >
              Gjenåpne abonnement
            </LargeButton>
          </div>
        </Fade>
      </Grid>
    </>
  )
}

export default PotButtons
