import React, { useEffect, useState, useRef } from "react";
import auth from "../auth";
import globalState from "../state/GlobalState";
import { useState as useGlobalState } from "@hookstate/core";
import Version from "../version";
import ServerState from "../controls/ServerState";
import { useConnect } from "dotnetify";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import ReactLoading from "react-loading";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
    TextField,
    TextFieldLowerCase,
    StyledTextField,
    StyledMultilineTextField
} from "../controls/TextField";
import Icon from "../components/Selfcare/Common/Icon";
import {
    Grid,
    FormControlLabel,
    Checkbox,
    List,
    ListItem
} from "@mui/material";
import { LargeButton, SmallButton } from "../controls/components/Buttons";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import OwnerIcon from "../assets/img/svg/owner.svg";
import UserIcon from "../assets/img/svg/user.svg";
import { Box, BoxTitle } from "../controls/Box";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Icons from "../assets/img/Icons.svg";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/nb';
import 'dayjs/locale/sv';
import { nbNO, svSE } from '@mui/x-date-pickers/locales';

export default function ChangeAddresses(props) {
    const { t } = useTranslation();
    var biData, cbState;
    const callbackState = sessionStorage.CallbackStateRet;
    if (callbackState) {
        if (callbackState !== "[object Object]") {
            cbState = JSON.parse(callbackState);
            sessionStorage.removeItem("CallbackStateRet");
        } else {
        }
    }
    const bankIdData = sessionStorage.BankIdData;
    if (bankIdData) {
        biData = JSON.parse(bankIdData);
        sessionStorage.removeItem("BankIdData");
    }
    const { vm, state, setState } = useConnect(
        "ChangeAddresses",
        {
            buzy: true,
            tabIndex: 0,
            EnterLastDigitsDialogOpen: false,
            EnterLastDigitsDialogOpenUser: false,
            NoMatchDialogOpen: false,
            ConfirmAddressDialogOpen: false,
            BankIdActive: false,
            EventListener: "",
            ContactPhone: "",
            EmailAddress: "",
            CoAddress: "",
            AddressLine: "",
            PostalCode: "",
            City: ""
        },
        {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            vmArg: {
                SubscriptionNo: props.SubscriptionNo,
                Type: props.type,
                HostUrl: window.location.host,
                Path: window.location.pathname,
                BankIdData: biData,
                CallbackState: cbState
            },
            exceptionHandler: _ => auth.signOut()
        }
    );
    const gstate = useGlobalState(globalState);
    const theme = useTheme();
    const isSweden = gstate.IsSweden.get();
    const isNorway = gstate.IsNorway.get();

    const handleDateChange = date => {
        var d = date.format('YYYY-MM-DD');
        setState({ UserBirthdate: d });
    };

    const handleSave = () => {
        setState({ buzy: true, ErrorField: "" });
        vm.$dispatch({
            PerformChange: {
                Firstname: state.Firstname,
                Lastname: state.Lastname,
                CoAddress: state.CoAddress,
                AddressLine: state.AddressLine,
                PostalCode: state.PostalCode,
                City: state.City,
                EmailAddress: state.EmailAddress,
                ContactPhone: state.ContactPhone
            }
        });
    };
    const handleUserSave = () => {
        vm.$dispatch({
            PerformUserChange: {
                Firstname: state.UserFirstname,
                Lastname: state.UserLastname,
                Birthdate: state.UserBirthdate,
                AddressLine: state.UserAddressLine,
                PostalCode: state.UserPostalCode,
                EmailAddress: state.UserEmailAddress,
                IsUserAddressPrimary: state.IsUserAddressPrimary,
                AllowEmailPassword: state.AllowEmailPassword,
                LastDigits: state.LastDigits
            }
        });
        setState({ buzy: true, ErrorField: "" });
    };
    useEffect(() => {
        if (
            state.BankIdUrl !== undefined &&
            state.BankIdUrl !== null &&
            state.BankIdUrl !== ""
        ) {
            window.location.href = state.BankIdUrl;
        }
    }, [state.BankIdUrl]);

    const anchor = useRef(null);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const postcodelen = isSweden ? 5 : 4;
    const dateformat = isNorway ? "dd.MM.yyyy" : "yyyy-MM-dd";
    const dateplace = isNorway ? "dd.mm.åååå" : "åååå-mm-dd";

    const personNotFoundInfo = [
        <>
            Sjekk at <b>postnummeret</b> er lik adressen oppført i{" "}
            <b>folkeregisteret</b>
        </>,
        <>
            Sjekk at hele <b>navnet</b> er skrevet helt likt som navnet oppført i{" "}
            <b>folkeregisteret</b>
        </>,
        <>
            Sjekk at <b>fødselsdato</b> er skrevet riktig
        </>
    ];

    return <>
        <ServerState
            serverState={state}
            setState={setState}
            handleClose={props.handleClose}
            handleRefresh={props.handleRefresh}
        />
        {state.IsAdministrating && (
            <div
                style={{
                    backgroundColor: "black",
                    color: "white",
                    padding: "5px",
                    textAlign: "center"
                }}
            >
                <span
                    dangerouslySetInnerHTML={{
                        __html: t("selfcare:info.now-administrating-number", {
                            0: state.AdministratedMsisdn
                        })
                    }}
                />
            </div>
        )}
        <Dialog
            open={state.EnterLastDigitsDialogOpen}
            onClose={() => {
                setState({ EnterLastDigitsDialogOpen: false, buzy: false });
            }}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{ style: { borderRadius: "10px" } }}
        >
            <DialogTitle id="responsive-dialog-title">
                <div style={{ display: "flex" }}>
                    <Icon name="edit" size={36} color="#e91e63" />
                    <span style={{ marginLeft: "20px", fontWeight: "700" }}>
                        Sjekk med fødselsnummer
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                Ingen eller flere treff funnet i folkeregisteret
                <br />
                <br />
                Du kan fullføre fødselsdatoen din med de fem siste sifrene og prøve
                igjen
                <br />
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item style={{ fontSize: "1rem", textAlign: "right" }} xs={6}>
                        {state.SsnStart} -&nbsp;
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            autoFocus
                            disabled={state.buzy || state.AddressDisabled}
                            localState={state}
                            setLocalState={setState}
                            label="Siste 5 sifrene"
                            id="LastDigits"
                            name="LastDigits"
                            type="tel"
                            maxLength={5}
                            labelWidth="150px"
                        />
                    </Grid>
                </Grid>
                {state.AddressLookupErrorText && (
                    <div
                        style={{
                            width: "100%",
                            padding: "5px",
                            color: "#e91e63",
                            fontSize: "12px"
                        }}
                    >
                        {state.AddressLookupErrorText}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <LargeButton
                    disabled={state.buzy}
                    onClick={() => {
                        setState({ AddressLookupErrorText: "", buzy: true });
                        vm.$dispatch({ SetLastDigits: state.LastDigits });
                    }}
                >
                    OK
                </LargeButton>
                <LargeButton
                    color="secondary"
                    variant="text"
                    onClick={() => {
                        setState({
                            EnterLastDigitsDialogOpen: false,
                            buzy: false,
                            AddressLookupErrorText: ""
                        });
                    }}
                >
                    AVBRYT
                </LargeButton>
            </DialogActions>
        </Dialog>
        <Dialog
            open={state.ConfirmAddressDialogOpen}
            onClose={() => {
                setState({ ConfirmAddressDialogOpen: false, buzy: false });
            }}
            aria-labelledby="responsive-dialog-title"
            style={{ borderRadius: "10px" }}
        >
            <DialogTitle id="responsive-dialog-title">
                <div style={{ display: "flex" }}>
                    <span style={{ marginLeft: "20px", fontWeight: "700" }}>
                        Adresse fra Folkeregister
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <div>
                    <b>{state.NewAddressLine}</b>
                </div>
                <div>
                    <b>{state.NewPostalCode}</b> <b>{state.NewCity}</b>
                </div>
                <div style={{ marginTop: "10px" }}>
                    Ønsker du å bruke adressen fra folkeregisteret?
                </div>
            </DialogContent>
            <DialogActions>
                <LargeButton
                    disabled={state.buzy}
                    onClick={() => {
                        setState({ buzy: true });
                        vm.$dispatch({ AcceptNewAddress: true });
                    }}
                >
                    OK
                </LargeButton>
                <LargeButton
                    disabled={state.buzy}
                    color="secondary"
                    variant="text"
                    onClick={() => {
                        setState({ ConfirmAddressDialogOpen: false, buzy: false });
                    }}
                >
                    AVBRYT
                </LargeButton>
            </DialogActions>
        </Dialog>
        <Dialog
            open={state.NoMatchDialogOpen}
            onClose={() => {
                setState({ NoMatchDialogOpen: false, buzy: false });
            }}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{
                style: {
                    borderRadius: "10px",
                    alignItems: "center",
                    paddingBottom: "1rem"
                }
            }}
        >
            <DialogTitle id="responsive-dialog-title" style={{ paddingBottom: 0 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}
                >
                    <svg
                        className={`icon icon-close`}
                        fill={theme.palette.primary.main}
                        width={69}
                        height={69}
                    >
                        <use xlinkHref={`${Icons}#icon-close`} />
                    </svg>
                    <b style={{ marginTop: ".5rem" }}>Person ikke funnet</b>
                </div>
            </DialogTitle>
            <DialogContent>
                <List>
                    {personNotFoundInfo.map((content, index) => (
                        <ListItem
                            key={index}
                            style={{ display: "flex", alignItems: "flex-start" }}
                        >
                            <FiberManualRecordIcon
                                style={{
                                    fontSize: "small",
                                    color: theme.palette.primary.main,
                                    marginTop: ".125rem",
                                    marginRight: ".5rem"
                                }}
                            />
                            <p style={{ margin: 0 }}>{content}</p>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <LargeButton
                    disabled={state.buzy}
                    onClick={() => {
                        setState({ NoMatchDialogOpen: false, buzy: false });
                    }}
                >
                    Prøv på nytt
                </LargeButton>
            </DialogActions>
        </Dialog>
        <Dialog // TODO: Duplicate, reuse
            open={state.EnterLastDigitsDialogOpenUser}
            onClose={() => {
                setState({ EnterLastDigitsDialogOpenUser: false, buzy: false });
            }}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{ style: { borderRadius: "10px" } }}
        >
            <DialogTitle id="responsive-dialog-title">
                <div style={{ display: "flex" }}>
                    <Icon name="edit" size={36} color="#e91e63" />
                    <span style={{ marginLeft: "20px", fontWeight: "700" }}>
                        Sjekk med fødselsnummer
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                Ingen eller flere treff funnet i folkeregisteret
                <br />
                <br />
                Du kan fullføre fødselsdatoen din med de fem siste sifrene og prøve
                igjen
                <br />
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item style={{ fontSize: "1rem", textAlign: "right" }} xs={6}>
                        {state.SsnStart} -&nbsp;
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            autoFocus
                            disabled={state.buzy || state.AddressDisabled}
                            localState={state}
                            setLocalState={setState}
                            label="Siste 5 sifrene"
                            id="LastDigits"
                            name="LastDigits"
                            type="tel"
                            maxLength={5}
                            labelWidth="150px"
                        />
                    </Grid>
                </Grid>
                {state.AddressLookupErrorText && (
                    <div
                        style={{
                            width: "100%",
                            padding: "5px",
                            color: "#e91e63",
                            fontSize: "12px"
                        }}
                    >
                        {state.AddressLookupErrorText}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <LargeButton
                    disabled={state.buzy}
                    onClick={() => {
                        setState({ AddressLookupErrorText: "", buzy: true });
                        handleUserSave();
                    }}
                >
                    OK
                </LargeButton>
                <LargeButton
                    color="secondary"
                    variant="text"
                    onClick={() => {
                        setState({
                            EnterLastDigitsDialogOpenUser: false,
                            buzy: false,
                            AddressLookupErrorText: ""
                        });
                    }}
                >
                    AVBRYT
                </LargeButton>
            </DialogActions>
        </Dialog>
        {state.BankIdActive && (
            <>
                <div
                    style={{
                        display: "flex",
                        flexFlow: "column nowrap",
                        justifyContent: "flex-start",
                        alignItems: "center"
                    }}
                >
                    <div
                        style={{
                            fontSize: "16px",
                            marginTop: "30px",
                            marginBottom: "39px",
                            padding: "0px 17px"
                        }}
                    >
                        Godkjenn endringer med BankID
                    </div>
                    {state.BankIdRetry && (
                        <>
                            <Box background>
                                <BoxTitle>BankID</BoxTitle>
                                {state.BankIdErrorMessage}
                            </Box>
                            <LargeButton
                                style={{ marginTop: "20px" }}
                                onClick={() => {
                                    vm.$dispatch({ InitBankId: true });
                                }}
                            >
                                Prøv igjen
                            </LargeButton>
                        </>
                    )}
                </div>
            </>
        )}
        {!state.BankIdActive && (
            <div id="address">
                <Tabs
                    selectedIndex={state.tabIndex}
                    onSelect={index => setState({ tabIndex: index })}
                >
                    <TabList>
                        <Tab
                            disabled={state.tab1Disabled}
                            style={{ width: "40%", textAlign: "center" }}
                        >
                            {isNorway ? "Eier og SIM" : "Ägare och SIM"}
                            <img
                                src={OwnerIcon}
                                alt=""
                                height="24px"
                                style={{ marginLeft: "10px", marginBottom: "-5px" }}
                            />
                        </Tab>
                        <Tab style={{ width: "40%", textAlign: "center" }}>
                            {isNorway ? "Bruker" : "Användare"}
                            <img
                                src={UserIcon}
                                alt=""
                                height="24px"
                                style={{ marginLeft: "10px", marginBottom: "-5px" }}
                            />
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <>
                            <div
                                style={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: "#FFF",
                                    padding: "20px"
                                }}
                            >
                                <Grid container justifyContent="center">
                                    <Grid item xs={12} sm={10}>
                                        <div style={{ fontSize: "14px", maxWidth: "900px" }}>
                                            {isNorway
                                                ? "Endre adressen på eier for å endre hvor SIM-kort og eventuell papirfaktura sendes."
                                                : "Ändra adressen för ägaren, dit SIM-kort och eventuella pappersfakturor skickas."}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <Grid
                                container
                                direction="column"
                                justifyContent="space-around"
                                alignItems="center"
                                style={{ paddingTop: "17px" }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={10}
                                    style={{ width: "100%", padding: "0px 17px 17px 17px" }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-around"
                                        alignItems="center"
                                    >
                                        {isNorway && state.IsResidential && (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "10px",
                                                    backgroundColor:
                                                        theme.palette.boxGreyBackground.main,
                                                    padding: "10px 30px",
                                                    fontFamily: theme.typography.fontFamily2
                                                }}
                                            >
                                                Vil du endre personen som skal eie og betale for
                                                abonnementet?{" "}
                                                <a
                                                    href=""
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        props.showDialog("changeOwner");
                                                    }}
                                                    style={{ color: theme.palette.primary.main }}
                                                >
                                                    Se endre eier
                                                </a>
                                            </div>
                                        )}
                                        {isNorway && state.IsCorporate && (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "10px",
                                                    backgroundColor:
                                                        theme.palette.boxGreyBackground.main,
                                                    padding: "10px 30px",
                                                    fontFamily: theme.typography.fontFamily2
                                                }}
                                            >
                                                Firmanavn og organisasjonsnummer kan ikke endres. For
                                                å endre juridisk eier, vennligst fyll ut
                                                eierskifteskjema som du finner{" "}
                                                <a
                                                    href=""
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        props.openUrl(
                                                            "https://chilimobil.no/media/1059/skjema_for_eierskifte.pdf",
                                                            true
                                                        );
                                                    }}
                                                    style={{ color: theme.palette.primary.main }}
                                                >
                                                    her
                                                </a>
                                            </div>
                                        )}
                                        {isNorway && state.IsCorporate && (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "10px",
                                                    textAlign: "center",
                                                    fontSize: "16px",
                                                    fontWeight: 700,
                                                    marginTop: "17px",
                                                    backgroundColor:
                                                        theme.palette.boxGreyBackground.main,
                                                    color: theme.palette.primary.main,
                                                    padding: "10px 30px",
                                                    fontFamily: theme.typography.fontFamily2
                                                }}
                                            >
                                                Kontakt kundeservice på 915 02 445 for oppdatering av
                                                adresse.
                                            </div>
                                        )}
                                        {isSweden && state.IsCorporate && (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "10px",
                                                    textAlign: "center",
                                                    fontSize: "16px",
                                                    fontWeight: 700,
                                                    marginTop: "17px",
                                                    backgroundColor:
                                                        theme.palette.boxGreyBackground.main,
                                                    color: theme.palette.primary.main,
                                                    padding: "10px 30px",
                                                    fontFamily: theme.typography.fontFamily2
                                                }}
                                            >
                                                Kontakta kundservice för hjälp med att uppdatera
                                                adressen.
                                            </div>
                                        )}
                                        {state.AddressDisabledMessage && (
                                            <div
                                                style={{
                                                    borderRadius: "10px",
                                                    marginTop: "17px",
                                                    backgroundColor: "black",
                                                    color: "white",
                                                    padding: "10px",
                                                    textAlign: "center"
                                                }}
                                            >
                                                {state.AddressDisabledMessage}
                                            </div>
                                        )}
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                        >
                                            <div
                                                style={{
                                                    paddingTop: "20px",
                                                    paddingLeft: "10px",
                                                    fontWeight: "700"
                                                }}
                                            >
                                                {isNorway ? "Eier og betaler" : "Ägare och betalare"}
                                            </div>
                                            <Grid container>
                                                <div
                                                    style={{
                                                        padding: "0px 17px 17px 17px",
                                                        width: "100%"
                                                    }}
                                                >
                                                    <StyledMultilineTextField
                                                        fullWidth
                                                        disabled
                                                        label=""
                                                        value={state.Owner}
                                                        id="Owner"
                                                        name="Owner"
                                                        multiline
                                                        InputLabelProps={{ shrink: true }}
                                                        variant="outlined"
                                                        margin="dense"
                                                    />
                                                </div>
                                                <TextField
                                                    disabled={state.buzy || state.AddressDisabled}
                                                    localState={state}
                                                    setLocalState={setState}
                                                    label={t("selfcare:label.contactnumber")}
                                                    id="ContactPhone"
                                                    name="ContactPhone"
                                                    type="tel"
                                                    maxLength={10}
                                                />
                                                <TextFieldLowerCase
                                                    disabled={state.buzy || state.AddressDisabled}
                                                    localState={state}
                                                    setLocalState={setState}
                                                    label={t("selfcare:label.email")}
                                                    id="EmailAddress"
                                                    name="EmailAddress"
                                                    maxLength={50}
                                                    type="email"
                                                />
                                            </Grid>
                                            <div
                                                style={{
                                                    paddingTop: "20px",
                                                    paddingLeft: "10px",
                                                    fontWeight: "700"
                                                }}
                                            >
                                                {isNorway
                                                    ? "Adresse (SIM-kort, faktura)"
                                                    : "Adress (SIM-kort, faktura)"}
                                            </div>
                                            {state.CanRetrieveAddress && (
                                                <div style={{ marginLeft: "17px" }}>
                                                    <SmallButton
                                                        onClick={() => {
                                                            setState({ buzy: true });
                                                            isNorway
                                                                ? vm.$dispatch({ GetUpdatedAddressNO: true })
                                                                : vm.$dispatch({ GetUpdatedAddress: true });
                                                        }}
                                                        disabled={state.buzy || state.AddressDisabled}
                                                        style={{ marginTop: "10px", marginRight: "10px" }}
                                                    >
                                                        {t(
                                                            "selfcare:button.fetchaddressnationalregistry"
                                                        )}
                                                    </SmallButton>
                                                </div>
                                            )}
                                            <Grid container>
                                                <TextField
                                                    disabled={state.buzy || state.AddressDisabled}
                                                    localState={state}
                                                    setLocalState={setState}
                                                    label={t("selfcare:label.coname")}
                                                    id="CoAddress"
                                                    name="CoAddress"
                                                    maxLength={30}
                                                />
                                                <TextField
                                                    disabled={state.buzy || state.AddressDisabled}
                                                    label={t("selfcare:label.address")}
                                                    localState={state}
                                                    setLocalState={setState}
                                                    id="AddressLine"
                                                    name="AddressLine"
                                                />
                                                <TextField
                                                    disabled={state.buzy || state.AddressDisabled}
                                                    localState={state}
                                                    setLocalState={setState}
                                                    label={t("selfcare:label.postalcode")}
                                                    id="PostalCode"
                                                    name="PostalCode"
                                                    type="tel"
                                                    maxLength={postcodelen}
                                                    afterChange={value => {
                                                        vm.$dispatch({ Lookup: value });
                                                    }}
                                                />
                                                <TextField
                                                    disabled
                                                    localState={state}
                                                    setLocalState={setState}
                                                    label={t("selfcare:label.postalcity")}
                                                    id="City"
                                                    name="City"
                                                />
                                            </Grid>
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="space-around"
                                                alignItems="center"
                                            >
                                                <div style={{ paddingTop: "20px" }}>
                                                    <LargeButton
                                                        onClick={() => {
                                                            handleSave();
                                                        }}
                                                        disabled={state.buzy || state.AddressDisabled}
                                                        style={{ marginRight: "10px" }}
                                                    >
                                                        {isNorway ? "Godkjenn med BankID" : "Spara"}
                                                    </LargeButton>
                                                    <LargeButton
                                                        id="cancel"
                                                        variant="text"
                                                        color="secondary"
                                                        disabled={state.buzy}
                                                        onClick={() => {
                                                            props.handleClose();
                                                        }}
                                                    >
                                                        {t("selfcare:button.cancel")}
                                                    </LargeButton>
                                                </div>
                                                {state.buzy && (
                                                    <Grid item>
                                                        <ReactLoading
                                                            type={"bubbles"}
                                                            color={theme.palette.primary.main}
                                                            height={"50px"}
                                                            width={"50px"}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    </TabPanel>
                    <TabPanel>
                        <>
                            {state.IsAdministrating && (
                                <div
                                    style={{
                                        backgroundColor: "black",
                                        color: "white",
                                        padding: "5px",
                                        textAlign: "center"
                                    }}
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t("selfcare:info.now-administrating-number", {
                                                0: state.AdministratedMsisdn
                                            })
                                        }}
                                    />
                                </div>
                            )}
                            <div
                                style={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: "#FFF",
                                    padding: "20px"
                                }}
                            >
                                <Grid container justifyContent="center">
                                    <Grid item xs={12} sm={10}>
                                        <div style={{ fontSize: "14px", maxWidth: "900px" }}>
                                            {isNorway
                                                ? "Dette er informasjonen som vises i opplysningstjenester, på Min Side, på datavisningen over alle abonnement og ved innlogging hos Strex."
                                                : "Detta är informationen som visas för numret på Mitt Chili och i listan över samtliga abonnemang."}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <Grid container justifyContent="center">
                                    <Grid item xs={12} sm={10}>
                                        <div
                                            style={{
                                                padding: "20px 10px",
                                                fontSize: "16px",
                                                fontWeight: "700"
                                            }}
                                        >
                                            {t("selfcare:title.userinfo")}
                                        </div>
                                        <Grid container>
                                            <TextField
                                                disabled={state.buzy || state.BlockChangeAddress}
                                                label={t("selfcare:label.firstname")}
                                                id="UserFirstname"
                                                name="UserFirstname"
                                                maxLength={30}
                                                localState={state}
                                                setLocalState={setState}
                                            />
                                            <TextField
                                                disabled={state.buzy || state.BlockChangeAddress}
                                                label={t("selfcare:label.lastname")}
                                                id="UserLastname"
                                                name="UserLastname"
                                                maxLength={30}
                                                localState={state}
                                                setLocalState={setState}
                                            />
                                            <Grid item xs={12} sm={6} style={{ padding: "17px" }}>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                    adapterLocale={isNorway ? 'nb' : 'sv'}>
                                                    <MobileDatePicker
                                                        views={['year', 'month', 'day']}
                                                        localeText={isNorway ? nbNO.components.MuiLocalizationProvider.defaultProps.localeText : svSE.components.MuiLocalizationProvider.defaultProps.localeText}
                                                        slots={{ textField: StyledTextField }}
                                                        slotProps={{ textField: { size: "small", style: { width: '100%' } } }}
                                                        disabled={state.buzy || state.BlockChangeAddress}
                                                        label={t('selfcare:label.birthdate')}
                                                        disableFuture
                                                        value={dayjs(state.UserBirthdate)}
                                                        onChange={handleDateChange}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <TextField
                                                disabled={state.buzy || state.BlockChangeAddress}
                                                label={t("selfcare:label.address")}
                                                id="UserAddressLine"
                                                name="UserAddressLine"
                                                maxLength={30}
                                                localState={state}
                                                setLocalState={setState}
                                            />
                                            <TextField
                                                disabled={state.buzy || state.BlockChangeAddress}
                                                label={t("selfcare:label.postalcode")}
                                                id="UserPostalCode"
                                                name="UserPostalCode"
                                                type="tel"
                                                maxLength={postcodelen}
                                                localState={state}
                                                setLocalState={setState}
                                                afterChange={value => {
                                                    vm.$dispatch({ LookupUser: value });
                                                }}
                                            />
                                            <TextField
                                                disabled
                                                label={t("selfcare:label.postalcity")}
                                                id="UserCity"
                                                name="UserCity"
                                                localState={state}
                                                setLocalState={setState}
                                            />
                                            <TextFieldLowerCase
                                                disabled={state.buzy || state.BlockChangeAddress}
                                                label={t("selfcare:label.email")}
                                                id="UserEmailAddress"
                                                name="UserEmailAddress"
                                                maxLength={50}
                                                type="email"
                                                localState={state}
                                                setLocalState={setState}
                                            />
                                            <div style={{ padding: "0px 17px" }}>
                                                {isNorway && (
                                                    <div style={{ display: "flex" }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={state.IsUserAddressPrimary}
                                                                    onChange={e => {
                                                                        setState({
                                                                            IsUserAddressPrimary: e.target.checked
                                                                        });
                                                                    }}
                                                                    disabled={
                                                                        state.buzy || state.BlockChangeAddress
                                                                    }
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={t(
                                                                "selfcare:label.transferuserinfodirectoryservices"
                                                            )}
                                                            labelPlacement="end"
                                                            style={{ maxWidth: "20rem", marginRight: 0 }}
                                                        />
                                                        <IconButton onClick={e => setPopoverOpen(true)} ref={anchor} size="large">
                                                            <Icon
                                                                name="information"
                                                                size={24}
                                                                color={theme.palette.primary.main}
                                                            />
                                                        </IconButton>
                                                        <Popover
                                                            id={"popover"}
                                                            open={popoverOpen}
                                                            onClose={() => { }}
                                                            anchorEl={anchor.current}
                                                            anchorOrigin={{
                                                                vertical: "center",
                                                                horizontal: "left"
                                                            }}
                                                            transformOrigin={{
                                                                vertical: "center",
                                                                horizontal: "right"
                                                            }}
                                                        >
                                                            <div style={{ textAlign: "right" }}>
                                                                <IconButton
                                                                    role="button"
                                                                    aria-label={isSweden ? "Stäng" : "Lukk"}
                                                                    size="small"
                                                                    onClick={() => setPopoverOpen(false)}
                                                                >
                                                                    <Icon
                                                                        name="close"
                                                                        size={24}
                                                                        color={theme.palette.primary.main}
                                                                    />
                                                                </IconButton>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    padding: "10px",
                                                                    maxWidth: "400px",
                                                                    fontSize: "12px"
                                                                }}
                                                            >
                                                                <b>
                                                                    OBS! Om sperren "ikke i opplysningen" er
                                                                    aktivert, vil den overstyre denne
                                                                    sjekkboksen.
                                                                </b>
                                                            </div>
                                                        </Popover>
                                                    </div>
                                                )}
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={state.AllowEmailPassword}
                                                            onChange={e => {
                                                                setState({
                                                                    AllowEmailPassword: e.target.checked
                                                                });
                                                            }}
                                                            disabled={
                                                                state.buzy || state.BlockChangeAddress
                                                            }
                                                            color="primary"
                                                        />
                                                    }
                                                    label={t("selfcare:label.emailcanreceivepassword")}
                                                    labelPlacement="end"
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="space-around"
                                        direction="column"
                                        alignItems="center"
                                    >
                                        <div style={{ paddingTop: "20px" }}>
                                            <LargeButton
                                                onClick={() => {
                                                    handleUserSave();
                                                }}
                                                disabled={state.buzy || state.BlockChangeAddress}
                                                style={{ marginRight: "10px" }}
                                            >
                                                {t("selfcare:button.update")}
                                            </LargeButton>
                                            <LargeButton
                                                color="secondary"
                                                variant="text"
                                                id="cancel"
                                                disabled={state.buzy}
                                                onClick={() => {
                                                    props.handleClose();
                                                }}
                                            >
                                                {t("selfcare:button.cancel")}
                                            </LargeButton>
                                        </div>
                                        {state.buzy && (
                                            <Grid item>
                                                <ReactLoading
                                                    type={"bubbles"}
                                                    color={theme.palette.primary.main}
                                                    height={"50px"}
                                                    width={"50px"}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    </TabPanel>
                </Tabs>
            </div>
        )}
    </>;
}
