import React, { useEffect } from "react"
import Version from "../version"
import auth from "../auth"
import ServerState from "../controls/ServerState"
import { useConnect } from "dotnetify"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material/styles"
import { LargeButton, SmallButton } from "../controls/components/Buttons"
import Icon from "../components/Selfcare/Common/Icon"
import { Card, CardContent } from "@mui/material"
import Slider from "react-slick"
import { Grid } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import CircularProgress from "@mui/material/CircularProgress"
import ReactLoading from "react-loading"
import Collapse, { Panel } from "rc-collapse"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Radio from "@mui/material/Radio"
import TextField from "@mui/material/TextField"
import Popover from "@mui/material/Popover"
import IconButton from "@mui/material/IconButton"
import AppBar from "@mui/material/AppBar"
import { Transition } from "../extras"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import CloseIcon from "@mui/icons-material/Close"
import Iframe from "react-iframe"
import { YouTubeVideo } from "../controls/YouTubeVideo"
import viaplayLogo from "../assets/img/viaplay.png"

const ChangeService = ({ subscriptionNo, handleClose }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  var GroupId = window.sessionStorage.GroupId
  var ServiceNo = window.sessionStorage.ServiceNo
  var fromOrder = sessionStorage.FromOrder
  var biData, cbState
  const callbackState = sessionStorage.CallbackStateRet
  if (callbackState && callbackState !== "[object Object]") {
    cbState = JSON.parse(callbackState)
    sessionStorage.removeItem("CallbackStateRet")
  }
  const bankIdData = sessionStorage.BankIdData
  if (bankIdData) {
    biData = JSON.parse(bankIdData)
    sessionStorage.removeItem("BankIdData")
  }
  const { vm, state, setState } = useConnect(
    "ChangeService",
    {
      show: false,
      redirect: null,
      toUrl: null,
      Packages: null,
      ChangeTVServiceDialogOpen: false,
      ExternalDialogOpen: false,
      ActivateViaplayDialogOpen: false,
      OrderSent: false,
      OrderError: "",
      OrderOpen: "",
      ErrorField: "",
      ErrorText: "",
      DisableButtons: false,
      buzy: false,
      Type: "Loading",
      password: "",
      passCode: "",
      EnterPasswordDialogOpen: false,
      EnterPassCodeDialogOpen: false,
      PassCodeText: "",
      CancelTVServiceDialogOpen: false,
      anchorEl: null,
      ButtonText: " ",
      SendSimCard: false,
      SelectSimType: false,
      SelectedSimType: "",
      termsAccepted: false
    },
    {
      headers: {
        Authorization: "Bearer " + auth.getAccessToken(),
        ClientVersion: Version.getVersion(),
        ServerToken: window.sessionStorage.ServerToken
      },
      vmArg: {
        SubscriptionNo: subscriptionNo,
        FromOrder: fromOrder,
        GroupId,
        ServiceNo,
        HostUrl: window.location.host,
        Path: window.location.pathname,
        BankIdData: biData,
        CallbackState: cbState
      },
      exceptionHandler: _ => auth.signOut()
    }
  )

  useEffect(() => {
    setTimeout(function () {
      setState({ DisableButtons: state.OrderOpen })
    }, 10)
  }, [state.OrderOpen])

  useEffect(() => {
    if (
      state.BankIdUrl &&
      state.BankIdUrl !== "" &&
      state.BankIdUrl !== undefined &&
      state.BankIdUrl !== null
    ) {
      window.location.replace(state.BankIdUrl)
    }
  }, [state.BankIdUrl])

  const handleConfirmChangeTVServiceDialogClose = () => {
    vm.$dispatch({
      PerformChangeTVService: {
        SelectedServiceNo: state.SelectedPackage.ServiceNo,
        SelectedServiceName: state.SelectedPackage.Variant
      }
    })
    setState({ ChangeTVServiceDialogOpen: false, buzy: true })
  }
  const handleExternalDialogClose = () => {
    setState({ ExternalDialogOpen: false })
    setState({ redirect: true, toUrl: "/overview" })
  }
  const handleActivateViaplayDialogClose = () => {
    setState({ ActivateViaplayDialogOpen: false })
    setState({ redirect: true, toUrl: "/overview" })
  }

  const handleChangeTVServiceDialogClose = () => {
    setState({
      ChangeTVServiceDialogOpen: false,
      SelectedPackage: null,
      error: null
    })
  }

  const handleEnterPassCodeDialogClose = () => {
    setState({ EnterPassCodeDialogOpen: false })
    setState({ buzy: false })
  }

  const handleInputChange = name => event => {
    setState({ [name]: event.target.value, PasswordErrorText: "" })
  }
  const handleInputChange2 = (name, obj) => event => {
    if (event.target.type === "checkbox") {
      // Handle checkboxes
      if (!obj)
        setState({
          [name]: event.target.checked,
          ErrorField: "",
          ErrorText: ""
        })
      else setState({ [obj.objId]: { ...obj, [name]: event.target.checked } }) //
    } else {
      //Handle other controls
      if (!obj)
        setState({ [name]: event.target.value, ErrorField: "", ErrorText: "" })
      else setState({ [obj.objId]: { ...obj, [name]: event.target.value } }) //
    }
    //Clear any validation errors
    setState({ ErrorField: "", ErrorText: "", OrderError: "" })
  }

  const handleSelectPackage = selectedPackage => {
    if (!selectedPackage.CanChangeTo) {
      //Order package
      vm.$dispatch({ OrderTVService: selectedPackage.ServiceNo })
      setState({ buzy: true })
    } else {
      //Change package
      setState({
        SelectedPackage: selectedPackage,
        ChangeTVServiceDialogOpen: true
      })
    }
  }

  const handleClick = (event, popoverId) => {
    setState({
      openedPopoverId: popoverId,
      anchorEl: event.currentTarget
    })
  }

  const cancelTVService = service => {
    vm.$dispatch({
      CancelTVService: {
        ProductNo: service.ServiceNo,
        CancelAtEndOfPeriod: true
      }
    })
  }

  const handlePopupClose = () => {
    setState({
      openedPopoverId: null,
      anchorEl: null
    })
  }

  var settings = {
    arrows: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          infinite: state.Options && state.Options.length > 2 ? true : false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          infinite: state.Options && state.Options.length > 1 ? true : false
        }
      }
    ]
  }

  return (
    <>
      <ServerState
        serverState={state}
        setState={setState}
        handleClose={handleClose}
      />
      <div style={{ height: "100vh" }}>
        <Dialog
          open={state.EnterPasswordDialogOpen}
          onClose={(_, reason) => {
            if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
              setState({ EnterPasswordDialogOpen: false, buzy: false })
            }
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <div style={{ display: "flex" }}>
              <Icon name="edit" size={36} color={theme.palette.primary.main} />
              <span style={{ marginLeft: "20px", fontWeight: "700" }}>
                {t("selfcare:dialog.codesetbylegalowner")}
              </span>
            </div>
          </DialogTitle>
          <DialogContent>
            {state.PasswordText}
            <br />
            <br />
            <TextField
              style={{ width: "100%" }}
              id="password"
              label={t("selfcare:label.code")}
              type="password"
              value={state.password}
              onChange={handleInputChange("password")}
              margin="normal"
            />
            {state.PasswordErrorText && (
              <div
                style={{
                  width: "100%",
                  padding: "5px",
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  fontSize: "12px"
                }}
              >
                {state.PasswordErrorText}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <LargeButton
              onClick={() => {
                vm.$dispatch({
                  SetPassword: {
                    Password: state.password,
                    Add: true,
                    SimType: state.SelectedSimType
                  }
                })
              }}
            >
              {t("selfcare:button.proceeduc")}
            </LargeButton>
            <LargeButton
              color="secondary"
              variant="text"
              onClick={() => {
                setState({ EnterPasswordDialogOpen: false, buzy: false })
              }}
            >
              {t("selfcare:button.canceluc")}
            </LargeButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={state.ActivateViaplayDialogOpen}
          onClose={(_, reason) => {
            if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
              handleActivateViaplayDialogClose()
            }
          }}
          TransitionComponent={Transition}
          transitionDuration={500}
          style={{ zIndex: 3000 }}
        >
          <DialogContent style={{ justifyContent: "center" }}>
            <div style={{ fontSize: "1.5rem" }}>{state.ViaplayTitle}</div>
            <div style={{ marginTop: "20px" }}>
              Du behöver nu registrera dig hos Viaplay. Vi öppnar registreringen
              i ett nytt fönster.
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <LargeButton
              href={state.OpenUrl}
              target="_blank"
              onClick={() => {
                handleActivateViaplayDialogClose()
              }}
            >
              Till Viaplay
            </LargeButton>
          </DialogActions>
        </Dialog>

        <Dialog
          fullScreen
          open={state.ExternalDialogOpen}
          onClose={handleExternalDialogClose}
          TransitionComponent={Transition}
          transitionDuration={500}
          style={{ zIndex: 3000 }}
        >
          <AppBar color="primary">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleExternalDialogClose}
                aria-label="close"
                size="large"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" style={{ flexGrow: 1 }}></Typography>
              <LargeButton autoFocus onClick={handleExternalDialogClose}>
                Stäng
              </LargeButton>
            </Toolbar>
          </AppBar>
          <div style={{ height: "64px" }} />
          <Iframe
            url={state.ExternalUrl}
            id="external-frame"
            width="100%"
            height="100%"
            display="initial"
            position="relative"
            border="none"
          />
        </Dialog>
        <Dialog
          open={state.EnterPassCodeDialogOpen}
          onClose={(_, reason) => {
            if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
              handleEnterPassCodeDialogClose()
            }
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <div style={{ display: "flex" }}>
              <Icon name="edit" size={36} color={theme.palette.primary.main} />
              <span style={{ marginLeft: "20px", fontWeight: "700" }}>
                Tast inn kode fra SMS
              </span>
            </div>
          </DialogTitle>
          <DialogContent>
            {state.PassCodeText}
            <br />
            <TextField
              autoFocus
              style={{ width: "100%" }}
              id="passcode"
              label={t("selfcare:label.code")}
              type="tel"
              value={state.passCode}
              onChange={handleInputChange("passCode")}
              margin="normal"
            />
            {state.PassCodeErrorText && (
              <div
                style={{
                  width: "100%",
                  padding: "5px",
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  fontSize: "12px"
                }}
              >
                {state.PassCodeErrorText}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <LargeButton
              onClick={() => {
                vm.$dispatch({
                  SetPassCode: {
                    PassCode: state.passCode,
                    Add: true,
                    SimType: state.SelectedSimType
                  }
                })
              }}
            >
              {t("selfcare:button.send-order-uc")}
            </LargeButton>
            <LargeButton
              color="secondary"
              variant="text"
              onClick={handleEnterPassCodeDialogClose}
            >
              {t("selfcare:button.cancel-uc")}
            </LargeButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={state.ChangeTVServiceDialogOpen}
          onClose={handleChangeTVServiceDialogClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {t("selfcare:title.change-tv-package", {
              packageName: state.GroupName
            })}
          </DialogTitle>
          <DialogContent>
            {t("selfcare:dialog.wishtochangepackage", {
              0: state.CurrentService ? state.CurrentService.Variant : null,
              1: state.SelectedPackage ? state.SelectedPackage.Variant : null
            })}
            <br />
            <br />
            {state.SelectedPackage && state.SelectedPackage.IsDowngrade
              ? t("selfcare:dialog.changeeffectiveafterperiod")
              : null}
          </DialogContent>
          <DialogActions>
            <LargeButton onClick={handleConfirmChangeTVServiceDialogClose}>
              {t("selfcare:button.confirmuc")}
            </LargeButton>
            <LargeButton
              color="secondary"
              variant="text"
              onClick={handleChangeTVServiceDialogClose}
            >
              {t("selfcare:button.canceluc")}
            </LargeButton>
          </DialogActions>
        </Dialog>
        {state.Type === "Loading" && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: "100vh" }}
          >
            <Grid item>
              <ReactLoading
                type={"bubbles"}
                color={theme.palette.primary.main}
                height={"50px"}
                width={"50px"}
              />
            </Grid>
          </Grid>
        )}
        {state.Type !== "Loading" && (
          <div className="noselect">
            {state.IsAdministrating && (
              <div
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "5px",
                  textAlign: "center"
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("selfcare:info.now-administrating-number", {
                      0: state.AdministratedMsisdn
                    })
                  }}
                />
              </div>
            )}
            <div
              style={{
                backgroundColor: theme.palette.primary.main,
                color: "#FFF",
                padding: "20px"
              }}
              className="white-links"
            >
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={10}>
                  <Icon name={state.Image} size={48} color="#FFFFFF" />
                  <div style={{ fontSize: "24px" }}>{state.Title}</div>
                  <div style={{ fontSize: "18px", fontWeight: 700 }}>
                    {state.InitialFee && (
                      <div>
                        {t("selfcare:item.initialfee")}: {state.InitialFee}
                      </div>
                    )}
                    {state.MonthlyFee && (
                      <div>
                        {t("selfcare:item.permonth")}: {state.MonthlyFee}
                      </div>
                    )}
                  </div>
                  {state.Description && (
                    <div
                      style={{
                        paddingTop: "10px",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontFamily: theme.typography.fontFamily2
                      }}
                      dangerouslySetInnerHTML={{ __html: state.Description }}
                    />
                  )}
                  {state.LongDescription && (
                    <Collapse
                      accordion={true}
                      style={{
                        fontSize: "14px",
                        fontFamily: theme.typography.fontFamily2
                      }}
                    >
                      <Panel header={t("selfcare:label.readmore")}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: state.LongDescription
                          }}
                        />
                      </Panel>
                    </Collapse>
                  )}
                  {state.VideoUrl && <YouTubeVideo VideoUrl={state.VideoUrl} />}
                </Grid>
              </Grid>
            </div>
            <div>
              {!state.CanChangeServices && (
                <div
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    padding: "5px",
                    textAlign: "center",
                    borderBottom: "1px grey solid"
                  }}
                >
                  {t("selfcare:info.howtochangeservices")}
                </div>
              )}
              <div>
                {(state.Type === "Service" || state.Type === "Group") && (
                  <div style={{ paddingLeft: "20px", paddingTop: "20px" }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={10}>
                        <Grid
                          container
                          justifyContent="center"
                          direction="column"
                        >
                          {state.SelectSimType && (
                            <React.Fragment>
                              <FormControl
                                error={state.ErrorField === "SelectedSimType"}
                              >
                                <FormGroup>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          color="primary"
                                          checked={
                                            state.SelectedSimType === "Standard"
                                          }
                                          onChange={() => {
                                            setState({
                                              SelectedSimType: "Standard",
                                              SendSimCard: true,
                                              ErrorField: "",
                                              ErrorText: ""
                                            })
                                          }}
                                          value="Standard"
                                          disabled={
                                            state.DisableButtons ||
                                            !state.CanChangeServices
                                          }
                                        />
                                      }
                                      label={t("selfcare:label.physical-sim")}
                                      labelPlacement="end"
                                    />
                                    <>
                                      <IconButton
                                        aria-owns="Standard"
                                        aria-haspopup="true"
                                        aria-label={t("selfcare:label.help")}
                                        onClick={e => {
                                          handleClick(e, "Standard")
                                        }}
                                        size="large"
                                      >
                                        <Icon
                                          name="information"
                                          size={24}
                                          color={theme.palette.primary.main}
                                        />
                                      </IconButton>
                                      <Popover
                                        id="Standard"
                                        open={
                                          state.openedPopoverId === "Standard"
                                        }
                                        anchorEl={state.anchorEl}
                                        onClose={handlePopupClose}
                                        anchorOrigin={{
                                          vertical: "center",
                                          horizontal: "left"
                                        }}
                                        transformOrigin={{
                                          vertical: "center",
                                          horizontal: "right"
                                        }}
                                      >
                                        {" "}
                                        <>
                                          <div style={{ textAlign: "right" }}>
                                            <IconButton
                                              role="button"
                                              aria-label={
                                                state.IsSweden
                                                  ? "Stäng"
                                                  : "Lukk"
                                              }
                                              size="small"
                                              onClick={() => handlePopupClose()}
                                            >
                                              <Icon
                                                name="close"
                                                size={24}
                                                color={
                                                  theme.palette.primary.main
                                                }
                                              />
                                            </IconButton>
                                          </div>
                                          <div
                                            style={{
                                              padding: "10px",
                                              maxWidth: "400px",
                                              fontSize: "12px"
                                            }}
                                          >
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: t(
                                                  "selfcare:info.after-order-sim-is-sent",
                                                  { 0: state.SimAddress }
                                                )
                                              }}
                                            />
                                          </div>
                                        </>
                                      </Popover>
                                    </>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          color="primary"
                                          checked={
                                            state.SelectedSimType === "eSim"
                                          }
                                          onChange={() => {
                                            setState({
                                              SelectedSimType: "eSim",
                                              SendSimCard: true,
                                              ErrorField: "",
                                              ErrorText: ""
                                            })
                                          }}
                                          value="eSim"
                                          disabled={
                                            state.DisableButtons ||
                                            !state.CanChangeServices
                                          }
                                        />
                                      }
                                      label={t("selfcare:label.esim")}
                                      labelPlacement="end"
                                    />
                                    <div>
                                      <IconButton
                                        aria-owns="eSim"
                                        aria-haspopup="true"
                                        aria-label={t("selfcare:label.help")}
                                        onClick={e => handleClick(e, "eSim")}
                                        size="large"
                                      >
                                        <Icon
                                          name="information"
                                          size={24}
                                          color={theme.palette.primary.main}
                                        />
                                      </IconButton>
                                      <Popover
                                        id="eSim"
                                        open={state.openedPopoverId === "eSim"}
                                        anchorEl={state.anchorEl}
                                        onClose={handlePopupClose}
                                        anchorOrigin={{
                                          vertical: "center",
                                          horizontal: "left"
                                        }}
                                        transformOrigin={{
                                          vertical: "center",
                                          horizontal: "right"
                                        }}
                                      >
                                        <>
                                          <div style={{ textAlign: "right" }}>
                                            <IconButton
                                              role="button"
                                              aria-label={
                                                state.IsSweden
                                                  ? "Stäng"
                                                  : "Lukk"
                                              }
                                              size="small"
                                              onClick={e => {
                                                handlePopupClose()
                                              }}
                                            >
                                              <Icon
                                                name="close"
                                                size={24}
                                                color={
                                                  theme.palette.primary.main
                                                }
                                              />
                                            </IconButton>
                                          </div>

                                          <div
                                            style={{
                                              padding: "10px",
                                              maxWidth: "400px",
                                              fontSize: "12px"
                                            }}
                                          >
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: t(
                                                  "selfcare:info.esim",
                                                  { 0: state.SimEmail }
                                                )
                                              }}
                                            />
                                          </div>
                                        </>
                                      </Popover>
                                    </div>
                                  </div>
                                </FormGroup>
                                <FormHelperText>
                                  {state.ErrorField === "SelectedSimType" &&
                                    state.ErrorText}
                                </FormHelperText>
                              </FormControl>
                            </React.Fragment>
                          )}
                          {state.SendSimCard &&
                            state.SelectedSimType === "Standard" && (
                              <Grid item>
                                <p>
                                  <b>{t("selfcare:item.simsentto")}:</b>
                                </p>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: state.SimAddress
                                  }}
                                />
                              </Grid>
                            )}
                          {state.SendSimCard &&
                            state.SelectedSimType === "eSim" && (
                              <Grid item>
                                <p>
                                  <b>{t("selfcare:info.qr-esim-sent")}:</b>
                                </p>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: state.SimEmail
                                  }}
                                />
                              </Grid>
                            )}
                          <Grid item>
                            {state.GroupHeader && (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: state.GroupHeader
                                }}
                              />
                            )}
                            <FormGroup>
                              {state.GroupType === "Single" &&
                                state.ServiceOptions &&
                                state.ServiceOptions.map(option => (
                                  <FormControlLabel
                                    key={option.ServiceNo}
                                    control={
                                      <Radio
                                        color="primary"
                                        checked={option.Checked}
                                        onChange={() => {
                                          vm.$dispatch({
                                            SelectionChanged: option.ServiceNo
                                          })
                                          setState({
                                            ErrorField: "",
                                            ErrorText: ""
                                          })
                                        }}
                                        value={option.ServiceNo}
                                        disabled={
                                          state.DisableButtons ||
                                          !state.CanChangeServices
                                        }
                                      />
                                    }
                                    label={option.Name}
                                    labelPlacement="end"
                                  />
                                ))}
                              {state.GroupType === "Multi" &&
                                state.ServiceOptions && (
                                  <>
                                    {!state.IsSweden && GroupId === "40" && (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            color="primary"
                                            checked={state.ServiceOptions.every(
                                              o => o.Checked
                                            )}
                                            onChange={() =>
                                              vm.$dispatch({
                                                ToggleAllServiceOptions: {}
                                              })
                                            }
                                            disabled={
                                              state.DisableButtons ||
                                              !state.CanChangeServices
                                            }
                                          />
                                        }
                                        label={
                                          <div
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "bold",
                                              marginRight: "1rem"
                                            }}
                                          >
                                            Aktiver alle sperrer
                                          </div>
                                        }
                                        labelPlacement="end"
                                        sx={{
                                          backgroundColor: "boxBackground.main",
                                          width: "fit-content",
                                          borderRadius: "10px"
                                        }}
                                      />
                                    )}
                                    {state.ServiceOptions.map(option => (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center"
                                        }}
                                        key={option.ServiceNo}
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              color="primary"
                                              checked={option.Checked}
                                              onChange={() =>
                                                vm.$dispatch({
                                                  SelectionChanged:
                                                    option.ServiceNo
                                                })
                                              }
                                              value={option.ServiceNo}
                                              disabled={
                                                state.DisableButtons ||
                                                !state.CanChangeServices
                                              }
                                            />
                                          }
                                          label={
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginRight: "-15px",
                                                fontSize: "12px"
                                              }}
                                            >
                                              {option.Name}
                                            </div>
                                          }
                                          labelPlacement="end"
                                        />
                                        {option.HelpText && (
                                          <>
                                            <IconButton
                                              aria-owns={option.ServiceNo}
                                              aria-haspopup="true"
                                              aria-label={t(
                                                "selfcare:label.help"
                                              )}
                                              onClick={e =>
                                                handleClick(e, option.ServiceNo)
                                              }
                                              size="large"
                                            >
                                              <Icon
                                                name="information"
                                                size={24}
                                                color={
                                                  theme.palette.primary.main
                                                }
                                              />
                                            </IconButton>
                                            <Popover
                                              id={option.ServiceNo}
                                              open={
                                                state.openedPopoverId ===
                                                option.ServiceNo
                                              }
                                              anchorEl={state.anchorEl}
                                              onClose={handlePopupClose}
                                              anchorOrigin={{
                                                vertical: "center",
                                                horizontal: "left"
                                              }}
                                              transformOrigin={{
                                                vertical: "center",
                                                horizontal: "right"
                                              }}
                                            >
                                              <>
                                                <div
                                                  style={{ textAlign: "right" }}
                                                >
                                                  <IconButton
                                                    role="button"
                                                    aria-label={
                                                      state.IsSweden
                                                        ? "Stäng"
                                                        : "Lukk"
                                                    }
                                                    size="small"
                                                    onClick={() =>
                                                      handlePopupClose()
                                                    }
                                                  >
                                                    <Icon
                                                      name="close"
                                                      size={24}
                                                      color={
                                                        theme.palette.primary
                                                          .main
                                                      }
                                                    />
                                                  </IconButton>
                                                </div>
                                                <div
                                                  style={{
                                                    padding: "10px",
                                                    maxWidth: "400px",
                                                    fontSize: "12px"
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                    __html: option.HelpText
                                                  }}
                                                />
                                              </>
                                            </Popover>
                                          </>
                                        )}
                                      </div>
                                    ))}
                                  </>
                                )}
                            </FormGroup>
                          </Grid>
                          {state.OrderOpen && (
                            <Grid item>
                              <div style={{ width: "100%" }}>
                                {t("selfcare:info.orderprocessed")}
                              </div>
                            </Grid>
                          )}
                          {state.IsCostControl && (
                            <Grid item>
                              <div style={{ width: "100%" }}>
                                <div>
                                  <b>
                                    Tjänsten Kostnadskontroll är{" "}
                                    {state.TurnOn
                                      ? "just nu inte aktiverad"
                                      : "aktiverad"}{" "}
                                    på ditt abonnemang
                                  </b>
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                  {state.TurnOn
                                    ? "När tjänsten Kostnadskontroll aktiveras betyder det att tex utlandssamtal, betalsamtal och andra betaltjänster inte kan nyttjas. Det betyder också att inga rörliga kostnader kan komma att belasta ditt abonnemang förutom den fasta månadsavgiften. Kostnadskontroll, helt enkelt."
                                    : "Om du stänger av tjänsten Kostnadskontroll så betyder det att du kan använda tjänster såsom utlandssamtal, betalsamtal och andra betaltjänster, vilka kan medföra rörliga och extra kostnader. Dessa kostnader kommer att belasta ditt abonnemang förutom den fasta månadsavgiften."}
                                  <br />
                                  <br />
                                  {state.PerformCreditCheck
                                    ? "Om du väljer att stänga av tjänsten Kostnadskontroll kommer en kreditkontroll att genomföras."
                                    : ""}
                                </div>
                              </div>
                            </Grid>
                          )}
                          {state.ShowSecretNumberOrder && (
                            <Grid
                              container
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                style={{ maxWidth: "400px", marginTop: "20px" }}
                              >
                                <Grid item style={{ marginRight: "10px" }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#9D9D9D"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                  </svg>
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    marginBottom: "26px",
                                    maxWidth: "90%",
                                    fontSize: "14px",
                                    fontFamily: theme.typography.fontFamily2
                                  }}
                                >
                                  Vis nummeret ditt som «skjult nummer» når du
                                  ringer andre vanlige nummer i Norge.
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                style={{ maxWidth: "400px" }}
                              >
                                <Grid item style={{ marginRight: "10px" }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#9D9D9D"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                  </svg>
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    marginBottom: "26px",
                                    maxWidth: "90%",
                                    fontSize: "14px",
                                    fontFamily: theme.typography.fontFamily2
                                  }}
                                >
                                  Nummer, navn og adresse unntas fra
                                  opplysningstjenester og all annen utlevering
                                  til allmenheten.
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                style={{ maxWidth: "400px" }}
                              >
                                <Grid item style={{ marginRight: "10px" }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="#9D9D9D"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                  </svg>
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    marginBottom: "26px",
                                    maxWidth: "90%",
                                    fontSize: "14px",
                                    fontFamily: theme.typography.fontFamily2
                                  }}
                                >
                                  BankID er påkrevd for å gjøre endringer
                                  tilknyttet denne tjenesten og din adresse.
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          <Grid item>
                            <Grid
                              container
                              direction="column"
                              justifyContent="space-around"
                              alignItems="center"
                            >
                              <div style={{ paddingTop: "10px" }}>
                                {state.NeedAccept && (
                                  <FormControlLabel
                                    style={{
                                      maxWidth: "500px",
                                      display: "table",
                                      marginBottom: "10px"
                                    }}
                                    control={
                                      <Checkbox
                                        id="termsAccepted"
                                        disabled={
                                          state.Disabled ||
                                          state.buzy ||
                                          state.OrderOpen
                                        }
                                        checked={state.termsAccepted}
                                        onChange={handleInputChange2(
                                          "termsAccepted"
                                        )}
                                        color="primary"
                                        style={{
                                          display: "table-cell",
                                          verticalAlign: "top"
                                        }}
                                      />
                                    }
                                    label={
                                      <div
                                        style={{
                                          fontSize: "14px",
                                          fontFamily:
                                            theme.typography.fontFamily2
                                        }}
                                      >
                                        <div
                                          style={{ textAlign: "left" }}
                                          dangerouslySetInnerHTML={{
                                            __html: state.AcceptHTML
                                          }}
                                        />
                                      </div>
                                    }
                                  />
                                )}
                                {state.ErrorField === "termsAccepted" && (
                                  <div
                                    style={{
                                      color: theme.palette.error.main,
                                      marginTop: "5px"
                                    }}
                                  >
                                    {state.ErrorText}
                                  </div>
                                )}
                              </div>
                              {state.ShowSecretNumberOrder && (
                                <div
                                  style={{
                                    textAlign: "center",
                                    marginTop: "20px"
                                  }}
                                >
                                  <div>Må bestilles med BankID av:</div>
                                  <div>
                                    <b>{state.BankIdUserFullname}</b>
                                  </div>
                                </div>
                              )}
                              {state.ShowSecretNumberCancel && (
                                <div
                                  style={{
                                    textAlign: "center",
                                    marginTop: "20px"
                                  }}
                                >
                                  <div>Må avbestilles med BankID av:</div>
                                  <div>
                                    <b>{state.BankIdUserFullname}</b>
                                  </div>
                                </div>
                              )}
                              {state.CancelNotification && (
                                <div style={{ color: "red" }}>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: state.CancelNotification
                                    }}
                                  />
                                </div>
                              )}
                              <div style={{ marginTop: "10px" }}>
                                <LargeButton
                                  onClick={() => {
                                    vm.$dispatch({
                                      PerformChange: {
                                        Add: true,
                                        SimType: state.SelectedSimType,
                                        TermsAccepted: state.termsAccepted
                                      }
                                    })
                                    setState({ buzy: true })
                                  }}
                                  disabled={
                                    !state.CanSave ||
                                    state.DisableButtons ||
                                    state.buzy ||
                                    !state.CanChangeServices ||
                                    state.CancelNotification
                                  }
                                  style={{ marginRight: "10px" }}
                                >
                                  {" "}
                                  {state.ButtonText}{" "}
                                </LargeButton>
                                <LargeButton
                                  color="secondary"
                                  variant="text"
                                  id="cancel"
                                  disabled={state.buzy}
                                  onClick={handleClose}
                                >
                                  {t("selfcare:button.cancel")}
                                </LargeButton>
                              </div>
                              <div style={{ paddingTop: "20px" }}>
                                {state.CanCancelService && (
                                  <LargeButton
                                    onClick={() => {
                                      vm.$dispatch({
                                        PerformChange: { Add: false }
                                      })
                                      setState({ buzy: true })
                                    }}
                                    disabled={
                                      state.DisableButtons ||
                                      state.buzy ||
                                      !state.CanChangeServices
                                    }
                                    style={{ marginRight: "10px" }}
                                  >
                                    {t("selfcare:button.cancelservice")}
                                  </LargeButton>
                                )}
                              </div>
                              {state.buzy && (
                                <Grid item>
                                  <ReactLoading
                                    type={"bubbles"}
                                    color={theme.palette.primary.main}
                                    height={"50px"}
                                    width={"50px"}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )}
                {state.Type === "TV" && (
                  <div style={{ paddingLeft: "20px", paddingTop: "20px" }}>
                    <form>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={10}>
                          <>
                            <Slider {...settings}>
                              {state.Options
                                ? state.Options.map(pack => (
                                    <Card raised key={pack.ServiceNo}>
                                      <CardContent>
                                        <Grid container justifyContent="center">
                                          <Grid item>
                                            <div
                                              style={{
                                                fontSize: "22px",
                                                width: "240px",
                                                height: "70px",
                                                textAlign: "center"
                                              }}
                                            >
                                              {pack.Variant}
                                            </div>
                                          </Grid>
                                        </Grid>
                                        <div
                                          style={{
                                            height: "180px",
                                            textAlign: "left"
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: pack.Description
                                          }}
                                        />
                                        <Grid
                                          container
                                          direction="column"
                                          justifyContent="center"
                                          alignItems="center"
                                        >
                                          <Grid item>
                                            <h3>{pack.Fee}</h3>
                                          </Grid>
                                          <Grid item>
                                            <div
                                              style={{
                                                height: "20px",
                                                color: "red",
                                                textAlign: "center"
                                              }}
                                            >
                                              {pack.Message}
                                            </div>
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              {pack.Ordered ? (
                                                <SmallButton disabled>
                                                  {t("selfcare:button.ordered")}
                                                </SmallButton>
                                              ) : !pack.Current ? (
                                                <SmallButton
                                                  disabled={
                                                    state.DisableButtons ||
                                                    state.buzy ||
                                                    !state.CanChangeServices
                                                  }
                                                  onClick={() =>
                                                    handleSelectPackage(pack)
                                                  }
                                                >
                                                  {pack.CanChangeTo
                                                    ? t(
                                                        "selfcare:button.change-to"
                                                      )
                                                    : t(
                                                        "selfcare:button.order"
                                                      )}
                                                </SmallButton>
                                              ) : (
                                                <SmallButton disabled>
                                                  {t("selfcare:button.current")}
                                                </SmallButton>
                                              )}
                                              {pack.CanCancel && (
                                                <SmallButton
                                                  onClick={() =>
                                                    cancelTVService(pack)
                                                  }
                                                  style={{
                                                    marginLeft: "10px"
                                                  }}
                                                >
                                                  {t(
                                                    "selfcare:button.cancelorder"
                                                  )}
                                                </SmallButton>
                                              )}
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    </Card>
                                  ))
                                : null}
                            </Slider>
                            <Grid container justifyContent="center">
                              <Grid item>
                                <div style={{ paddingTop: "40px" }}>
                                  {state.buzy && (
                                    <CircularProgress
                                      size={48}
                                      style={{
                                        color: "#C2185B",
                                        position: "absolute",
                                        top: "20%",
                                        left: "50%",
                                        marginTop: -12,
                                        marginLeft: -12
                                      }}
                                    />
                                  )}
                                  {state.OrderOpen && (
                                    <div>
                                      {t("selfcare:info.orderprocessed")}
                                    </div>
                                  )}
                                  <LargeButton
                                    id="back"
                                    disabled={state.buzy}
                                    onClick={handleClose}
                                  >
                                    <Icon
                                      name="back"
                                      size={32}
                                      color="#FFFFFF"
                                    />
                                    {t("selfcare:button.back")}
                                  </LargeButton>
                                </div>
                              </Grid>
                            </Grid>
                          </>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                )}
                {state.Type === "Activate" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center"
                    }}
                  >
                    {state.ViaplayPreOfferText ? (
                      <div style={{ padding: "20px" }}>
                        {state.ViaplayPreOfferText}
                      </div>
                    ) : (
                      <>
                        {state.ShowViaplayOffer && (
                          <div
                            style={{
                              maxWidth: "360px",
                              margin: "20px 10px",
                              borderRadius: "10px",
                              border: "1px solid #555",
                              textAlign: "center",
                              backgroundColor: "#FFF",
                              boxShadow: "4px 9px 22px 0 rgba(0, 0, 0, 0.13)",
                              WebkitBoxShadow:
                                "4px 9px 22px 0 rgba(0, 0, 0, 0.13)",
                              MozBoxShadow: "4px 9px 22px 0 rgba(0, 0, 0, 0.13)"
                            }}
                          >
                            <div
                              style={{ padding: "10px", textAlign: "center" }}
                            >
                              <p
                                style={{
                                  textAlign: "left",
                                  fontSize: "0.875rem"
                                }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: state.ViaplayText
                                  }}
                                />
                              </p>

                              <img
                                style={{ width: "100px", marginBottom: "10px" }}
                                src={viaplayLogo}
                                alt="Viaplay"
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-around"
                                }}
                              >
                                <SmallButton
                                  id="register-viaplay"
                                  disabled={state.ViaplayBuzy}
                                  onClick={() => {
                                    vm.$dispatch({ AcceptViaplayOffer: true })
                                    setState({ ViaplayBuzy: true })
                                  }}
                                >
                                  Acceptera erbjudande
                                </SmallButton>
                                <SmallButton
                                  variant="outlined"
                                  disabled={state.ViaplayBuzy}
                                  onClick={() => {
                                    vm.$dispatch({ AcceptViaplayOffer: false })
                                    setState({ ViaplayBuzy: true })
                                  }}
                                >
                                  Nej tack
                                </SmallButton>
                              </div>
                              {state.ViaplayBuzy && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexFlow: "row nowrap",
                                    justifyContent: "center",
                                    width: "100%"
                                  }}
                                >
                                  <ReactLoading
                                    type={"bubbles"}
                                    color={theme.palette.primary.main}
                                    height={"50px"}
                                    width={"50px"}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {state.ShowActivateViaplay && (
                          <div
                            style={{
                              maxWidth: "360px",
                              margin: "20px 10px",
                              borderRadius: "10px",
                              border: "1px solid #555",
                              textAlign: "center",
                              backgroundColor: "#FFF",
                              boxShadow: "4px 9px 22px 0 rgba(0, 0, 0, 0.13)",
                              WebkitBoxShadow:
                                "4px 9px 22px 0 rgba(0, 0, 0, 0.13)",
                              MozBoxShadow: "4px 9px 22px 0 rgba(0, 0, 0, 0.13)"
                            }}
                          >
                            <div
                              style={{ padding: "10px", textAlign: "center" }}
                            >
                              <p
                                style={{
                                  textAlign: "left",
                                  fontSize: "0.875rem"
                                }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: state.ViaplayText
                                  }}
                                />
                              </p>
                              <img
                                style={{ width: "100px", marginBottom: "10px" }}
                                src={viaplayLogo}
                                alt="Viaplay"
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-around"
                                }}
                              >
                                <SmallButton
                                  href={state.OpenUrl}
                                  target="_blank"
                                  style={{ marginRight: "5px" }}
                                  id="register-viaplay"
                                  disabled={state.ViaplayBuzy}
                                  onClick={() => {
                                    vm.$dispatch({ ActivateViaplay: true })
                                    setState({ ViaplayBuzy: true })
                                  }}
                                >
                                  {t("selfcare:button.activate-viaplay")}
                                </SmallButton>
                                <SmallButton
                                  variant="outlined"
                                  size="sm"
                                  disabled={state.ViaplayBuzy}
                                  onClick={() => {
                                    vm.$dispatch({ ActivateViaplay: false })
                                    setState({ ViaplayBuzy: true })
                                  }}
                                >
                                  {t("selfcare:button.cancelorder")}l
                                </SmallButton>
                              </div>
                              {state.ViaplayBuzy && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexFlow: "row nowrap",
                                    justifyContent: "center",
                                    width: "100%"
                                  }}
                                >
                                  <ReactLoading
                                    type={"bubbles"}
                                    color={theme.palette.primary.main}
                                    height={"50px"}
                                    width={"50px"}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
                {state.Type === "Activate" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center"
                    }}
                  >
                    <LargeButton
                      id="back"
                      disabled={state.buzy}
                      onClick={handleClose}
                    >
                      <Icon name="back" size={32} color="#FFFFFF" />
                      {t("selfcare:button.back")}
                    </LargeButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ChangeService
